body {
  margin: 0;
  /* display: flex;
  place-items: center;
  min-width: 320px;
  min-height: 100vh; */
}

@font-face {
  font-family: 'Sk-Modernist';
  src: url('./fonts/Sk-Modernist/Sk-Modernist-Bold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Sk-Modernist';
  src: url('./fonts/Sk-Modernist/Sk-Modernist-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

/* @font-face {
  font-family: "Sk-Modernist";
  src: url("./fonts/Sk-Modernist-Mono.otf") format("opentype");
  font-style: normal;
  font-weight: normal;
} */

@font-face {
  font-family: 'Gotham';
  src: url('./fonts/Gotham/Gotham-Book.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham';
  src: url('./fonts/Gotham/Gotham-Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham';
  src: url('./fonts/Gotham/Gotham-Bold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Manrope';
  src: url('./fonts/Manrope/Manrope-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.font-skmodernist {
  font-family: 'Sk-Modernist' !important;
}

.font-gotham {
  font-family: 'Gotham' !important;
}

.font-manrope {
  font-family: 'Manrope' !important;
}

.text-black {
  color: #000 !important;
}

.text-offwhite {
  color: #f2f2f2 !important;
}

.text-grey {
  color: #727272 !important;
}

.text-gray {
  color: #757575 !important;
}

.text-darkgrey {
  color: #393939 !important;
}

.text-lightgrey {
  color: #acacac !important;
}

.text-primary {
  color: #416ad4 !important;
}

.text-primary-1 {
  color: #61a3f2 !important;
}

.text-primary-2 {
  color: #1843a0 !important;
}

.text-secondary {
  color: #864ffd !important;
}

.text-mandatory {
  color: #f00000 !important;
}

.w-10 {
  width: 10% !important;
}
.w-20 {
  width: 20% !important;
}
.w-30 {
  width: 30% !important;
}
.w-40 {
  width: 40% !important;
}
.w-50 {
  width: 50% !important;
}
.w-60 {
  width: 60% !important;
}
.w-70 {
  width: 70% !important;
}
.w-80 {
  width: 80% !important;
}
.w-90 {
  width: 90% !important;
}
.w-100 {
  width: 100% !important;
}

.main-divider {
  background-color: #e4e4e4;
  height: 1.5px;
}

.personal-divider {
  background-color: #d0d0d0;
  height: 1.5px;
}

.valid-align-field {
  position: relative !important;
}

.valid-align-text {
  position: absolute !important;
  bottom: -23px !important;
  left: -10px !important;
  color: red !important;
  margin-bottom: 0px !important;
}

/* Login section starts here */

.bgImage {
  background-image: url('assets/login-bg-admin.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
}

.bgMain {
  background-color: #ffffff;
  /* your desired background color */
}

.logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.primary-color-1 {
  background-color: #1843a0 !important;
}

.primary-color-2 {
  background-color: #864ffd !important;
}

.secondary-color-1 {
  background-color: #61a3f2 !important;
}

.secondary-black {
  background-color: #393939 !important;
}

.MuiTelInput-TextField .MuiTypography-root {
  font-family: 'Gotham' !important;
  color: black !important;
  font-size: 14px;
}

.MuiTelInput-IconButton {
  padding: 0 8px 0 0 !important;
}

.MuiTelInput-TextField .MuiOutlinedInput-input {
  padding-left: 0px !important;
}

/* Login section ends here */

/* Sidebar section starts here */

.sidebar-drawer .MuiDrawer-paper {
  background-color: #864ffd !important;
}

.sidebar-drawer .MuiListItemText-root .MuiTypography-root {
  font-family: 'Sk-Modernist' !important;
  font-size: 16px;
  color: #dddddd;
}

.sidebar-drawer .submenu-list .MuiListItemText-root .MuiTypography-root {
  font-family: 'Sk-Modernist' !important;
  font-size: 14px;
  color: #dddddd;
}

.sidebar-title {
  font-family: 'Sk-Modernist' !important;
  font-size: 14px !important;
  color: #dddddd;
  padding-left: 25px;
  margin-bottom: 10px;
  text-transform: uppercase !important;
}

.sidebar-drawer .MuiDivider-root.sidebar-divider {
  border: 1px solid #ffffff33 !important;
}

/* .css-g4mkvu-MuiPaper-root-MuiAppBar-root {
  z-index: 1100 !important;
  margin-left: 66px !important;
  width: calc(100% - 66px) !important;
}

.css-j37rtz-MuiPaper-root-MuiAppBar-root {
  margin-left: 260px !important;
  width: calc(100% - 260px) !important;
  z-index: 1201 !important;
} */
/* Sidebar section ends here */

.MuiInputBase-input.MuiOutlinedInput-input {
  /* border: 1px solid #E4E4E4 !important; */
  /* border-radius: 5px !important; */
  /* background: #FAFAFA !important; */
  font-size: 15px;
  color: #727272;
  opacity: 1;
  font-family: 'Gotham' !important;
  padding: 13px 14px !important;
}

.MuiInputBase-input,
.MuiInputBase-input::placeholder {
  font-family: 'Gotham' !important;
  color: #727272 !important;
  font-size: 14px !important;
  opacity: 100% !important;
}

.MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input .MuiSelect-select {
  font-family: 'Gotham' !important;
  color: #727272 !important;
  font-size: 14px !important;
  padding: 13px 14px !important;
  height: 1.4375em;
}

.MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input {
  padding: 5px !important;
}

textarea {
  border-radius: 6px !important;
  border: 1px solid #d4d4d4 !important;
  padding: 14px !important;
  font-family: 'Gotham' !important;
  color: #727272 !important;
  font-size: 14px !important;
}

.MuiFormControlLabel-root .MuiFormControlLabel-label {
  font-size: 14px;
  color: #393939;
  font-family: 'Gotham' !important;
}

.MuiCheckbox-root.Mui-checked,
.MuiCheckbox-root.MuiCheckbox-indeterminate {
  color: #864ffd !important;
}

.btn-create {
  background: #864ffd !important;
  color: #fff !important;
  font-family: 'Gotham' !important;
  font-size: 15px !important;
  text-transform: capitalize !important;
  border-radius: 6px !important;
  padding: 8px 16px !important;
  font-weight: 400 !important;
  max-height: 43px;
}

/* Table section starts here */
.MuiTableHead-root .MuiTableCell-head {
  background-color: #f7f7f7 !important;
  color: #727272 !important;
  font-family: 'Sk-Modernist' !important;
  font-size: 13px !important;
  padding: 8px 10px !important;
}

.MuiTableBody-root
  .MuiTableRow-root
  .MuiTableCell-body.MuiTableCell-root:first-child {
  color: #393939;
}

.MuiTableBody-root .MuiTableCell-body {
  background-color: #ffffff !important;
  color: #727272;
  font-family: 'Gotham' !important;
  font-size: 13px !important;
  padding: 5px 10px !important;
}

.MuiTypography-root.text-status-active {
  /* background: #fff; */
  color: #00b257 !important;
  /* text-decoration: none !important; */
  /* border: 1px solid #00B257; */
  font-weight: 400;
  font-size: 13px;
  font-family: 'Gotham' !important;
  text-transform: capitalize !important;
  /* box-sizing: border-box; */
  /* border-radius: 6px; */
  /* padding: 3px 16px !important; */
  /* width: 100px; */
  /* margin: 0 auto; */
}

.MuiTypography-root.text-status-inactive {
  color: #f04d23 !important;
  font-weight: 400;
  font-size: 13px;
  font-family: 'Gotham' !important;
  text-transform: capitalize !important;
}

.MuiTypography-root.text-status-contacted {
  color: #00b257 !important;
  font-weight: 400;
  font-size: 13px;
  font-family: 'Gotham' !important;
  text-transform: capitalize !important;
}

.MuiTypography-root.text-status-pending {
  color: #f3b900 !important;
  font-weight: 400;
  font-size: 13px;
  font-family: 'Gotham' !important;
  text-transform: capitalize !important;
}

.MuiTypography-root.text-status-hold {
  color: #4176ff !important;
  font-weight: 400;
  font-size: 13px;
  font-family: 'Gotham' !important;
  text-transform: capitalize !important;
}

.custom-table tbody td:first-child {
  /* Apply your custom styles here */
  font-size: 13px !important;
  color: #393939 !important;
}

.MuiTablePagination-toolbar {
  align-items: center !important;
}

.MuiTablePagination-selectLabel {
  margin-bottom: 0px;
}

.MuiTablePagination-displayedRows {
  margin-bottom: 0px;
}

.MuiPaginationItem-root {
  border: 1px solid #e4e4e4 !important;
  color: #864ffd !important;
  background: #fafafa !important;
}

.MuiPaginationItem-root.Mui-selected {
  color: #fff !important;
  background: #864ffd !important;
}

/* Table section ends here */

.custom-card-bg {
  background-color: #fff !important;
  border-radius: 14px !important;
  padding: 30px !important;
  margin: 0px 30px 24px 30px !important;
}

/* Modal section starts here */

.modal-main .MuiDialog-paper {
  border-radius: 18px !important;
}

.modal-body label {
  font-size: 15px;
  color: #363636;
  font-weight: 500;
}

.modal-header {
  padding-bottom: 0px;
}

.modal-footer {
  padding: 20px 24px !important;
}

.btn-cancel {
  background: #fafafa !important;
  border: 1px solid #e4e4e4 !important;
  color: #864ffd !important;
  font-size: 13px !important;
  font-weight: 400 !important;
  padding: 8px 20px !important;
  border-radius: 6px !important;
  text-transform: capitalize !important;
  max-height: 43px;
}

.btn-save {
  background: #864ffd !important;
  color: #fff !important;
  font-size: 13px !important;
  font-weight: 500 !important;
  padding: 5px 15px !important;
  border-radius: 6px !important;
  padding: 8px 20px !important;
  text-transform: capitalize !important;
}

.btn-edit {
  background: #61a3f2 !important;
  color: #fff !important;
  font-size: 13px !important;
  font-weight: 500 !important;
  padding: 5px 15px !important;
  border-radius: 6px !important;
  padding: 8px 20px !important;
  text-transform: capitalize !important;
}

.btn-add {
  /* background: #61A3F2 !important; */
  color: #fff !important;
  font-size: 50px !important;
  font-weight: 300 !important;
  height: 47px;
  min-width: 47px !important;
  border-radius: 6px !important;

  /* padding: 8px 20px !important; */
}

.MuiButton-root.add-btn {
  background: #61a3f2 !important;
  padding: 12px 0px !important;
  margin-top: 0px !important;
  width: 48px !important;
  min-width: 0px !important;
  border-radius: 6px !important;
  height: 47px !important;
}

.btn-approve {
  background: #00b257 !important;
  color: #fff !important;
  font-size: 13px !important;
  font-weight: 500 !important;
  padding: 5px 15px !important;
  border-radius: 6px !important;
  padding: 8px 20px !important;
  text-transform: capitalize !important;
}

.btn-hold {
  background: #f3b900 !important;
  color: #fff !important;
  font-size: 13px !important;
  font-weight: 500 !important;
  padding: 5px 15px !important;
  border-radius: 6px !important;
  padding: 8px 20px !important;
  text-transform: capitalize !important;
}

.btn-reject {
  background: #f04d23 !important;
  color: #fff !important;
  font-size: 13px !important;
  font-weight: 500 !important;
  padding: 5px 15px !important;
  border-radius: 6px !important;
  padding: 8px 20px !important;
  text-transform: capitalize !important;
}

.btn-info {
  background: #fffd !important;
  border: 1px solid #864ffd !important;
  border-radius: 6px !important;
  width: 48px !important;
  min-width: 48px !important;
  height: 48px !important;
  text-transform: capitalize !important;
}

.btn-tooltip {
  min-width: 0px !important;
}

.custom-border-header {
  position: relative !important;
  font-size: 20px !important;
  font-weight: bold !important;
  font-family: 'Sk-Modernist' !important;
}

.MuiDialog-paper {
  border-radius: 14px !important;
}

.custom-border-header::before {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 80%;
  height: 5px;
  background: linear-gradient(
    to right,
    #1843a0 33%,
    #864ffd 33% 66%,
    #61a3f2 66% 100%
  );
}

.MuiDialogActions-root {
  padding: 10px 24px 20px 24px !important;
}

/* Modal section ends here */

.add-button {
  border: 2px dashed #61a3f2 !important;
  padding: 12px 20px !important;
}

/* Scrollbar Section Starts Here */

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

/* Handle */

::-webkit-scrollbar-thumb {
  background: #864ffd8f;
  border-radius: 10px;
}

/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
  background: #864ffdff;
}

/* Scrollbar Section Ends Here */

.media {
  width: 80px;
  height: 72px;
  border-radius: 8px;
}

/* .treedata-card {
  background: var(--surface-card);
  margin-bottom: 1rem;
} */

/* .treedata-card tr {
  border: 1px solid grey;
  padding: 20px;
} */

.treedata-card thead tr th {
  background-color: #f7f7f7 !important;
  color: #727272 !important;
  font-family: 'Sk-Modernist' !important;
  font-size: 13px !important;
  padding: 10px 10px !important;
}

.treedata-card tbody tr td {
  background-color: #ffffff !important;
  color: #727272;
  font-family: 'Gotham' !important;
  font-size: 13px !important;
  padding: 8px 10px !important;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  display: table-cell;
  vertical-align: inherit;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  text-align: left;
}

tbody,
td,
tfoot,
th,
thead,
tr {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

.treedata-card .p-treetable-toggler {
  margin-right: 4px !important;
  margin-bottom: 2px !important;
}

.treedata-card thead tr th:first-child {
  width: 40% !important;
}

/* .treedata-card thead tr th:nth-child(2){
  width: 100px !important;
} */
/* Profile Reason Section Starts Here */

.ribbon {
  margin-left: -30px;
}

.ribbon a.rejected {
  background: #f04d23;
  border-radius: 0px 0 0 0px;
  color: #fff !important;
  display: inline-block !important;
  height: 41px;
  line-height: 38px;
  padding: 1px 40px 2px 19px;
  position: relative;
  margin: 0px 3px 0px 0;
  text-decoration: none;
  -webkit-transition: color 0.2s;
  font-size: 14px;
  font-family: 'Sk-Modernist' !important;
  font-weight: 400;
}

.ribbon a.rejected::after {
  background: #fff;
  border-bottom: 20px solid transparent;
  border-left: 23px solid #f04d23;
  border-top: 21px solid transparent;
  content: '';
  position: absolute;
  right: 0;
  top: 0;
}

.ribbon a.approved {
  background: #00b257;
  border-radius: 0px 0 0 0px;
  color: #fff !important;
  display: inline-block !important;
  height: 41px;
  line-height: 38px;
  padding: 1px 40px 2px 19px;
  position: relative;
  margin: 0px 3px 0px 0;
  text-decoration: none;
  -webkit-transition: color 0.2s;
  font-size: 14px;
  font-family: 'Sk-Modernist' !important;
  font-weight: 400;
}

.ribbon a.approved::after {
  background: #fff;
  border-bottom: 20px solid transparent;
  border-left: 23px solid #00b257;
  border-top: 21px solid transparent;
  content: '';
  position: absolute;
  right: 0;
  top: 0;
}

.ribbon a.pending {
  background: #f3b900;
  border-radius: 0px 0 0 0px;
  color: #fff !important;
  display: inline-block !important;
  height: 41px;
  line-height: 38px;
  padding: 1px 40px 2px 19px;
  position: relative;
  margin: 0px 3px 0px 0;
  text-decoration: none;
  -webkit-transition: color 0.2s;
  font-size: 14px;
  font-family: 'Sk-Modernist' !important;
  font-weight: 400;
}

.ribbon a.pending::after {
  background: #fff;
  border-bottom: 20px solid transparent;
  border-left: 23px solid #f3b900;
  border-top: 21px solid transparent;
  content: '';
  position: absolute;
  right: 0;
  top: 0;
}

/* Profile Reason Section Ends Here */

/* Homepage widget section starts here */

.homepage-widget-table .MuiTableBody-root .MuiTableCell-body {
  /* background-color: #FFFFFF !important; */
  color: #727272;
  font-family: 'Gotham' !important;
  font-size: 14px !important;
  padding: 14px 10px !important;
}

.homepage-widget-table .MuiInputBase-input.MuiOutlinedInput-input {
  /* border: 1px solid #E4E4E4 !important; */
  /* border-radius: 5px !important; */
  font-size: 15px;
  color: #727272;
  opacity: 1;
  font-family: 'Gotham' !important;
  padding: 10px 14px !important;
}

.homepage-widget-table tbody,
.homepage-widget-table tr,
.homepage-widget-table td {
  border-style: hidden !important;
}

.homepage-widget-table tbody tr:nth-child(odd) td {
  background-color: rgba(233, 224, 255, 0.3) !important;
  /* Set the background color for cells in odd columns */
}

.homepage-widget-table tbody tr:nth-child(even) td {
  background-color: #ffffff !important;
  /* Set the background color for cells in even columns */
}

/* Homepage widget section ends here */

/* Rich Text Secion Starts Here */
.MuiTiptap-RichTextContent-root.MuiTiptap-RichTextField-content {
  min-height: 280px;
}

.switch-editor-btn {
  background: #864ffd !important;
  color: #fff !important;
  text-transform: capitalize !important;
  font-size: 16px !important;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
}

.html-editor {
  border: 1px solid #e4e4e4 !important;
  border-radius: 6px !important;
}

/* Rich Text Secion Ends Here */

.MuiTypography-root .desc-text {
  font-family: 'Gotham' !important;
  font-size: 14px !important;
}

/* Widget Section Starts Here */
.card-widget {
  border: 1px solid #cccccc;
  border-radius: 8px !important;
  width: 100%;
  height: 170px !important;
  position: relative;
}

.card-widget-content {
  padding: 25px 25px !important;
  min-width: 65%;
}

.card-widget-media {
  max-width: 35%;
}

.card-widget-media .widget-img {
  height: 170px !important;
  width: 183px !important;
}

.btn-signin {
  background: #1843a0 !important;
  color: #fff !important;
  font-family: 'Sk-Modernist' !important;
  font-size: 15px !important;
  text-transform: capitalize !important;
  border-radius: 6px !important;
  padding: 5px 16px 8px 16px !important;
  font-weight: 700 !important;
  max-height: 38px;
}

.widget-check-icon {
  position: absolute;
  top: 10px;
  right: 12px;
}

textarea.widget-textarea {
  height: 46px !important;
  overflow-y: scroll !important;
}

.MuiButton-root.widget-add-btn {
  background: #864ffd !important;
  padding: 9px 0px !important;
  margin-top: 0px !important;
  width: 35px !important;
  min-width: 0px !important;
  border-radius: 6px !important;
  height: 35px !important;
}

.MuiButton-root.widget-add-btn img.add-btn-icon {
  width: 16px;
  height: 16px;
}

/* Widget Section Ends Here */

/* Tiles Section Ends Here */
.card-tiles {
  border: 1px solid #1843a0;
  border-radius: 8px !important;
  width: 120px;
  height: 120px !important;
  position: relative;
}

.card-tiles-media.MuiCardMedia-root {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.card-tiles .card-tiles-img {
  width: 48px;
  height: 48px;
}

.tile-field {
  width: 70px;
  height: 70px;
  border: 1px solid #d5d5d5;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tile-media {
  width: 38px;
  height: 38px;
}

.modal-tile-field {
  width: 38px;
  height: 38px;
  border: 1px solid #d5d5d5;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-tile-media {
  width: 26px;
  height: 26px;
}

/* Tiles Section Ends Here */

/* Tools And Services Section Starts Here */
.excel-icon {
  width: 24px;
  height: 26px;
}

/* Tools And Services Section Ends Here */

/* Subscription Section Starts Here */

.MuiButton-root.subscription-add-btn {
  background: #864ffd !important;
  padding: 0px 0px !important;
  margin-top: 0px !important;
  width: 45px !important;
  min-width: 0px !important;
  border-radius: 6px !important;
  height: 45px !important;
}

.MuiButton-root.subscription-update-btn {
  background: #864ffd !important;
  padding: 0px 0px !important;
  margin-top: 0px !important;
  width: 65px !important;
  min-width: 0px !important;
  border-radius: 6px !important;
  height: 45px !important;
}

.MuiButton-root.subscription-add-btn img.add-btn-icon {
  width: 20px;
  height: 20px;
}

/* Subscription Section Ends Here */

.ranking-dropdown .MuiList-root {
  height: 250px !important;
}

@media (min-width: 1536px) {
  .MuiContainer-root {
    max-width: none !important;
  }
}

.custom-MuiPopover-root {
  max-height: 'calc(100% - 300px) !important';
}

/* Product Catalog List Section Starts Here */
.product-catalog-list {
  width: 100%;
  max-width: 780px;
  margin: 0 auto !important;
  background-color: #fff;
}

.product-catalog-list .paper {
  padding: 16px;
  margin: auto;
  max-width: 780px;
  box-shadow: none;
}

.product-catalog-list .media {
  width: 350px;
  height: 274px;
}

.product-catalog-list .content {
  border: 2px solid #dcdcdc;
  padding: 20px 32px;
  position: relative;
  min-height: 275px;
  height: auto;
}

.product-catalog-list .price-btn {
  background-color: #864ffd;
  color: #fff;
  font-size: 20px;
  font-family: 'Sk-Modernist' !important;
  padding: 5px 20px;
  margin-bottom: 10px;
  bottom: 5px;
  position: absolute;
}

.download-btn {
  color: #864ffd !important;
  font-size: 18px !important;
  font-family: 'Sk-Modernist' !important;
  font-weight: 700 !important;
  line-height: 20px !important;
  padding: 12px 25px !important;
  background-color: #fff !important;
  border: 1px solid #864ffd !important;
  border-radius: 4px !important;
  box-shadow: none;
}

.custom-border-header {
  position: relative !important;
  font-size: 20px !important;
  font-weight: bold !important;
  font-family: 'Sk-Modernist' !important;
}

.custom-border-header::before {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 80%;
  height: 5px;
  background: linear-gradient(to right,
          #1843a0 33%,
          #864ffd 33% 66%,
          #61a3f2 66% 100%);
  z-index: 1 !important;
}

.modal-footer.MuiDialogActions-root {
  padding: 10px 24px 20px 24px !important;
}

.modal-footer {
  padding: 20px 24px !important;
}

.main-table .MuiTableCell-root.nodata-cell {
  font-size: 18px !important;
  font-weight: bold;
}