
.fs-10 { font-size: 0.625rem !important; }
.fs-11 { font-size: 0.688rem !important; }
.fs-12 { font-size: 0.75rem !important; }
.fs-13 { font-size: 0.813rem !important; }
.fs-14 { font-size: 0.875rem !important; }
.fs-15 { font-size: 0.938rem !important; }
.fs-16{font-size:1rem !important}
.fs-17{font-size:1.063rem !important}
.fs-18{font-size:1.125rem !important}
.fs-19 { font-size: 1.188rem !important; }
.fs-20 { font-size: 1.25rem !important; }
.fs-21 { font-size: 1.313rem !important; }
.fs-22 { font-size: 1.375rem !important; }
.fs-23 { font-size: 1.438rem !important; }
.fs-24 { font-size: 1.5rem !important; }
.fs-25 { font-size: 1.563rem !important; }
.fs-26 { font-size: 1.625rem !important; }
.fs-27 { font-size: 1.688rem !important; }
.fs-28 { font-size: 1.75rem !important; }
.fs-29 { font-size: 1.813rem !important; }
.fs-30 { font-size: 1.875rem !important; }
.fs-32{font-size:2rem !important;  }
.fs-34{font-size:2.125rem !important; }
.fs-36{font-size:2.25rem !important; }
.fs-38{font-size:2.375rem !important; }
.fs-40{font-size:2.5rem !important; }
.fs-42{font-size:2.625rem !important; }
.fs-46{font-size:2.875rem !important; }
.fs-48{font-size:3rem !important; }
.fs-50{font-size:3.125rem !important;}
.fs-55{font-size:3.438rem !important;}
.fs-60{font-size:3.75rem !important;}
.fs-65{font-size:4.063rem !important;}
.fs-70{font-size:4.375rem !important;}
.fs-75{font-size:4.688rem !important;}
.fs-80{font-size:5rem !important;}
.fs-85{font-size:5.313rem !important;}
.fs-90{font-size:5.625rem !important;}
.fs-100{font-size:6.25rem !important;}
.text-spacing-2{letter-spacing: 2px !important;}
.line-height{line-height: 1.2;}
.line-height-normal{line-height: 1.5;}
.line-height-20 {line-height: 20px;}

.fw-hair {font-weight: 100!important;}
.fw-exlight {font-weight: 200!important;}
.fw-light {font-weight: 300!important;}
.fw-normal {font-weight: 400!important;}
.fw-medium {font-weight: 500!important;}
.fw-semibold {font-weight: 600!important;}
.fw-bold {font-weight: bold!important;}
.fw-exbold {font-weight: 900!important;}


.letter-spacing-normal{letter-spacing: 0.02em;}

